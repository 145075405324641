// import 'initializers/turbolinks.js'
// import Vue from 'vue'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdaptor from 'vue-turbolinks'
// import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss'

// import VueSweetalert2 from 'vue-sweetalert2';
// import 'vue-loading-overlay/dist/vue-loading.css';
// import 'sweetalert2/dist/sweetalert2.min.css';

// require("@rails/ujs").start();
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import 'application/sweet-alert-confirm';
// import VueResource from 'vue-resource'
// import Vue from 'vue/dist/vue.js'

document.addEventListener("DOMContentLoaded", () => {
  var element = document.getElementById("Menu");
  var element2 = document.getElementById("Lang");
  
  if (element != null ) {
    var app_menu = new Vue({
      el: "#Menu",
      methods: {
        modif_menu_state() {
          this.menu = !this.menu;
        },
        modif_lang_state() {
          this.lang_choices = !this.lang_choices;
        },
      },
      data: {
        menu: false,
        lang_choices: false,
      }
    })
  }
  if (element2 != null ) {
    var app_menu = new Vue({
      el: "#Lang",
      methods: {
        modif_lang_state() {
          this.lang_choices = !this.lang_choices;
        },
      },
      data: {
        lang_choices: false,
      }
    })
  }
})
